<mat-card
  (click)="snackBarRef.dismiss()"
  class="w-100 helper-color-primary-800 no-out-footprint no-bacgound">
  <mat-card-header>
    <mat-card-title> {{ data.type | titlecase }} on: {{ data.source }} </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <span class="helper-color-primary-800"> {{ data.content | titlecase }} </span></mat-card-content
  >
</mat-card>
