import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '../ui-components/notification/notification.component';
import { Message } from '../models/message-model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _snackBar: MatSnackBar = inject(MatSnackBar);

  private ShowMessage(message: Message, duration = 2500) {
    const panel_class = 'notification-panel-'.concat(message.type);

    this._snackBar.openFromComponent(NotificationComponent, {
      duration,
      data: message,
      panelClass: [panel_class],
    });
  }

  Error(source: string, message: string) {
    this.ShowMessage(
      {
        content: message,
        source: source,
        type: 'error',
      },
      3000
    );
  }

  Succes(source: string, message: string) {
    this.ShowMessage(
      {
        content: message,
        source: source,
        type: 'succes',
      },
      1000
    );
  }

  Info(source: string, message: string) {
    this.ShowMessage({
      content: message,
      source: source,
      type: 'info',
    });
  }

  Warning(source: string, message: string) {
    this.ShowMessage({
      content: message,
      source: source,
      type: 'warn',
    });
  }

  GetErrorText(error: unknown): string {
    let messageText = '';

    if (error instanceof HttpErrorResponse) {
      const status = error.status;
      switch (status) {
        case 400:
          messageText = 'Incorrect data';
          break;
        case 401:
          messageText = 'Auththorisation error';
          break;
        case 403:
          messageText = 'Action is forbidden on server';
          break;
        case 404:
          messageText = 'Not found';
          break;
        default:
          if (status >= 500 && status <= 599) {
            messageText = 'Error on server';
            break;
          }

          if (status >= 400 && status <= 499) {
            messageText = 'Incorrect data';
            break;
          }

          messageText = error.status.toString().concat(' ').concat(error.statusText);

          break;
      }
    } else if (typeof error === 'string') {
      messageText = error;
    } else {
      messageText = 'Unknown error: ' + (error as any)?.message;
    }
    return messageText;
  }
}
